<template>
  <v-container
    fluid
    style="background-color: #E5E5E5;"
  >
    <template v-if="isLoading">
      <v-overlay :value="isLoading">
        <v-progress-circular
          color="purple lighten-4"
          indeterminate
          size="50"
        />
      </v-overlay>
    </template>

    <template v-else>
      <v-row class="mx-6">
        <v-col>
          <v-card class="px-6 py-6">
            <v-row>
              <v-col class="pt-0">
                <span>
                  Pastikan foto yang diterima sesuai dengan ketentuan Travikr seperti:
                </span>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="py-0">
                <v-icon color="green">
                  mdi-check-bold
                </v-icon>
                <span>
                  Foto tidak boleh blur
                </span>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="py-1">
                <v-icon color="green">
                  mdi-check-bold
                </v-icon>
                <span>
                  Foto tidak boleh pixelated
                </span>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pt-0 pb-1">
                <v-icon color="green">
                  mdi-check-bold
                </v-icon>
                <span>
                  Media terlihat jelas
                </span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          class="ml-9 mr-9 mt-n4 mb-5"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Aset"
            color="purple"
            single-line
            hide-details
            clearable
            outlined
            dense
            background-color="white"
            @keyup.enter.native="filterData"
            @click:append="filterData"
            @click:clear="filterClear"
          />
        </v-col>
      </v-row>

      <div class="d-flex flex-wrap mt-n5 px-6">
        <v-card
          v-for="(v, i) in photos"
          :key="i"
          class="mx-auto mt-2"
          max-width="380"
          height="460"
        >
          <v-img
            style="cursor: pointer; hover"
            :src="v.mainImage"
            height="235"
            lazy-src="@/assets/image-loader.png"
            @click="zoomMediaImage(v.mainImage)"
          />

          <v-card-text class="mt-1 mb-n6 ml-2">
            <v-row>
              <v-col class="mt-n4 mb-n4">
                <span
                  style="font-size: 16px;"
                >
                  {{ v.prtName }}
                </span>

                <span class="mx-2">
                  &#9679;
                </span>

                <span
                  style="font-size: 16px;"
                >
                  {{ v.prtNameLegal }}
                </span>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="mb-n2">
                <span
                  style="font-size: 16px; font-weight: bold;"
                >
                  {{ v.assetCode }}
                </span>
              </v-col>
            </v-row>
          </v-card-text>

          <template v-if="v.enableMainScreen">
            <v-card-text>
              <v-row>
                <v-col class="px-5">
                  <button
                    class="main-button accept"
                    :disabled="disableVerificationButton"
                    @click="accept(i)"
                  >
                    <span
                      style="font-size: 20px;"
                      class="accept-button-text"
                    >&#10004;</span>
                    <span class="accept-button-text ml-2">Terima Foto</span>
                  </button>
                </v-col>
              </v-row>
              <v-row class="mt-n2">
                <v-col class="px-5">
                  <button
                    class="main-button reject"
                    :disabled="disableVerificationButton"
                    @click="reject(i)"
                  >
                    <span
                      style="font-size: 20px;"
                      class="reject-button-text"
                    >&#10060;</span>
                    <span class="reject-button-text ml-2">Tolak Foto</span>
                  </button>
                </v-col>
              </v-row>
            </v-card-text>
          </template>

          <template v-if="v.enableAcceptScreen">
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <template v-if="v.uploadedImageLink === ''">
                    <v-img
                      style="cursor: pointer; hover"
                      src="@/assets/image-loader.png"
                      height="100"
                      contain
                    />
                  </template>
                  <template v-else>
                    <v-img
                      style="cursor: pointer; hover"
                      :src="v.uploadedImageLink"
                      height="100"
                      contain
                      @click="zoomMediaImage(v.uploadedImageLink)"
                    />
                  </template>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <div class="mt-1">
                    <button
                      class="upload-button"
                      @click="upload(i)"
                    >
                      <v-icon class="pb-0">
                        mdi-upload
                      </v-icon>
                      <span class="upload-button-text ml-2">Upload Foto</span>
                    </button>
                  </div>
                  <input
                    :ref="i"
                    type="file"
                    style="display: none"
                    @change="changeFile($event, i)"
                  >
                  <br>
                  <div class="d-flex justify-end mt-2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{on, attrs}">
                        <button
                          class="save-button"
                          v-bind="attrs"
                          :style="saveButtonStyle"
                          @click="save(i, 1)"
                          v-on="on"
                          @mouseenter="mouseEnter(1)"
                          @mouseleave="mouseLeave(1)"
                        >
                          <v-icon :color="saveIconColor">
                            mdi-content-save
                          </v-icon>
                        </button>
                      </template>
                      <span>Simpan</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{on, attrs}">
                        <button
                          class="cancel-button"
                          v-bind="attrs"
                          :style="cancelButtonStyle"
                          @click="cancel(i)"
                          v-on="on"
                          @mouseenter="mouseEnter(0)"
                          @mouseleave="mouseLeave(0)"
                        >
                          <v-icon :color="cancelIconColor">
                            mdi-rotate-left
                          </v-icon>
                        </button>
                      </template>
                      <span>Batal</span>
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </template>

          <template v-if="v.enableRejectScreen">
            <v-card-text>
              <v-row>
                <v-col>
                  <div class="mt-1">
                    <v-textarea
                      ref="approvalReasonTextArea"
                      v-model="approvalReason"
                      placeholder="Alasan untuk Penolakan foto.."
                      outlined
                      color="purple"
                      no-resize
                      height="80"
                      background-color="#FAFAFA"
                      :error-messages="approvalReasonErrorMessages"
                    />
                  </div>
                  <div class="d-flex justify-end mt-n6">
                    <v-tooltip bottom>
                      <template v-slot:activator="{on, attrs}">
                        <button
                          class="save-button"
                          v-bind="attrs"
                          :style="saveButtonStyle"
                          @click="save(i, 0)"
                          v-on="on"
                          @mouseenter="mouseEnter(1)"
                          @mouseleave="mouseLeave(1)"
                        >
                          <v-icon :color="saveIconColor">
                            mdi-content-save
                          </v-icon>
                        </button>
                      </template>
                      <span>Simpan</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{on, attrs}">
                        <button
                          class="cancel-button"
                          v-bind="attrs"
                          :style="cancelButtonStyle"
                          @click="cancel(i)"
                          v-on="on"
                          @mouseenter="mouseEnter(0)"
                          @mouseleave="mouseLeave(0)"
                        >
                          <v-icon :color="cancelIconColor">
                            mdi-rotate-left
                          </v-icon>
                        </button>
                      </template>
                      <span>Batal</span>
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </template>
        </v-card>
      </div>

      <v-row>
        <v-col class="pb-0">
          <v-pagination
            v-model="page"
            :length="pageCount"
            color="purple"
            @input="onChangePage"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-center pt-0">
          <span style="font-size: 12px; font-style: italic;">
            Page {{ page }} of {{ pageCount || 1 }}
          </span>
        </v-col>
      </v-row>

      <!-- Zoom Image -->
      <div
        v-if="zoomImage"
        class="modal-image"
      >
        <span
          class="modal-image-close"
          @click="zoomImage = false"
        >&times;</span>
        <img
          class="modal-image-content"
          :src="srcZoomImage"
        >
      </div>
    </template>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      isLoading: true,
      disableVerificationButton: false,
      photos: [
        {
          identifier: 0,
          enableMainScreen: true,
          enableAcceptScreen: false,
          enableRejectScreen: false,
          photoLink: '@/assets/image-loader.png',
          uploadImage: undefined,
          uploadedImageLink: '@/assets/image-loader.png',
          uploadedImageHash: '',
        },
      ],

      approvalReason: '',
      approvalReasonErrorMessages: '',

      zoomImage: false,
      srcZoomImage: '@/assets/image-loader.png',

      saveButtonColor: '#FAFAFA',
      saveIconColor: '#7A1878',
      cancelButtonColor: '#FAFAFA',
      cancelIconColor: '#808080',

      page: 1,
      pageCount: 0,
      currentPage: 1,

      search: '',
    }),

    computed: {
      saveButtonStyle () {
        return 'border: 1px solid #F2F2F2; background-color:' + this.saveButtonColor
      },
      cancelButtonStyle () {
        return 'border: 1px solid #F2F2F2; background-color:' + this.cancelButtonColor
      },
    },

    watch: {
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        const vm = this

        const requestBody = {
          page: this.currentPage,
          limit: 25,
          searchq: this.search,
        }

        await axios.post('/t/assetImage', requestBody).then((res) => {
          if (res.data.status === 200) {
            // console.log(res.data.data.list)
            this.photos = []
            res.data.data.list.map((v, i) => {
              const photo = {
                assetCode: v.ass_code,
                identifier: i,
                mainImage: v.name,
                oldImageHash: v.hash,
                newImageHash: undefined,
                enableMainScreen: true,
                enableAcceptScreen: false,
                enableRejectScreen: false,
                photoLink: '@/assets/image-loader.png',
                downloadLink: v.download_link,
                uploadImage: undefined,
                uploadedImageLink: '',
                uploadedImageHash: '',
                prtName: v.prt_name,
                prtNameLegal: v.prt_name_legal,
              }
              vm.photos.push(photo)
            })

            vm.pageCount = res.data.data.pagination.page_total

            this.isLoading = false

            // this.checkAuth()
          }
        }).catch((e) => {
        })
      },

      // checkAuth () {
      //   const userMenus = localStorage.getItem('userMenus').split(',')

      //   if (userMenus.includes('ASSET_IMAGE_MOD')) {
      //     this.disableVerificationButton = false
      //   }
      // },

      filterData () {
        this.initialize()
      },

      filterClear () {
        this.search = ''
        this.initialize()
      },

      accept (i) {
        // console.log(this.photos[i])
        this.photos[i].enableMainScreen = false
        this.photos[i].enableAcceptScreen = true
        this.photos[i].enableRejectScreen = false
        window.open(this.photos[i].downloadLink)
      },

      async reject (i) {
        // console.log(this.photos[i])
        this.photos[i].enableMainScreen = false
        this.photos[i].enableAcceptScreen = false
        this.photos[i].enableRejectScreen = true
      },

      upload (i) {
        this.$refs[i][0].click()
      },

      async changeFile (e, i) {
        var file = e.target.files || e.dataTransfer.files
        if (!file.length) return
        // console.log(file[0])

        const baseCDNurl = process.env.VUE_APP_CDN_BASE_URL
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        const requestBody = new FormData()
        requestBody.append('image', file[0])

        await axios.post(baseCDNurl + '/t/assetImage/upload', requestBody, config).then((res) => {
          if (res.data.status === 200) {
            // console.log(res.data.data)
            this.photos[i].uploadedImageHash = res.data.data.tmp_file_hash
            this.photos[i].uploadedImageLink = URL.createObjectURL(e.target.files[0])
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
      },

      async save (i, key) {
        if (key) {
          if (this.photos[i].uploadedImageHash === '') return

          const requestBody = {
            img_hash: this.photos[i].oldImageHash,
            status: 1,
            new_img_hash: this.photos[i].uploadedImageHash,
          }

          await axios.post('/t/assetImage/mod', requestBody).then((res) => {
            if (res.data.status === 200) {
              // console.log(res.data.data)
              this.$toast.success(res.data.message)
            }
          }).catch((e) => {
            e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
          })

          this.initialize()
        } else {
          if (this.approvalReason === '') {
            this.approvalReasonErrorMessages = 'Alasan harus diisi'
            return
          }

          const requestBody = {
            img_hash: this.photos[i].oldImageHash,
            status: 0,
            decline_msg: this.approvalReason,
          }

          await axios.post('/t/assetImage/mod', requestBody).then((res) => {
            if (res.data.status === 200) {
              console.log(res.data.data)
              this.$toast.success(res.data.message)
              this.approvalReason = ''
              this.approvalReasonErrorMessages = ''
            }
          }).catch((e) => {
            e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
          })

          this.initialize()
        }
      },

      cancel (i) {
        this.approvalReason = ''
        this.approvalReasonErrorMessages = ''
        this.photos[i].enableMainScreen = true
        this.photos[i].enableAcceptScreen = false
        this.photos[i].enableRejectScreen = false
        this.photos[i].uploadedImageLink = ''
      },

      zoomMediaImage (link) {
        this.srcZoomImage = link
        this.zoomImage = true
      },

      mouseEnter (key) {
        if (key) {
          this.saveButtonColor = '#7A1878'
          this.saveIconColor = '#FFFFFF'
        } else {
          this.cancelButtonColor = '#CCCCCC'
          this.cancelIconColor = '#FFFFFF'
        }
      },

      mouseLeave (key) {
        if (key) {
          this.saveButtonColor = '#FAFAFA'
          this.saveIconColor = '#7A1878'
        } else {
          this.cancelButtonColor = '#FAFAFA'
          this.cancelIconColor = '#808080'
        }
      },

      onChangePage (e) {
        this.page = e
        this.currentPage = e
        this.initialize()
      },
    },
  }
</script>

<style scoped>
  #listImages {
    cursor: pointer;
  }

  *:focus {
    outline: 0 !important;
  }

  .main-button {
    border: 1px solid #F2F2F2;
    background: #FAFAFA;
    padding: 10px 10px;
    cursor: pointer;
    border-radius: 4px;
    width: 100%;
  }

  .upload-button {
    border: 1px solid #F2F2F2;
    background: #FAFAFA;
    padding-top: 6px;
    padding-bottom: 6px;
    cursor: pointer;
    border-radius: 4px;
    width: 100%;
  }

  .save-button {
    border: 1px solid #F2F2F2;
    background: #FAFAFA;
    padding-top: 6px;
    padding-bottom: 6px;
    cursor: pointer;
    border-radius: 4px;
    width: 45px;
    margin-right: 10px;
  }

  .cancel-button {
    border: 1px solid #F2F2F2;
    background: #FAFAFA;
    padding-top: 6px;
    padding-bottom: 6px;
    cursor: pointer;
    border-radius: 4px;
    width: 45px;
  }

  .reason-textarea {
    width: 100%;
  }

  .accept:hover {
    background: #5DB025;
    border-color: #5DB025;
    color: white;
  }

  .accept:hover .accept-button-text {
    color: white;
  }

  .reject:hover {
    background: #EA3E3A;
    border-color: #EA3E3A;
    color: white;
  }

  .reject:hover .reject-button-text {
    color: white;
  }

  .accept-button-text {
    color: #5DB025;
    margin-left: 2px;
    font-size: 16px;
    font-weight: bold;
  }

  .reject-button-text {
    color: #EA3E3A;
    margin-left: 2px;
    font-size: 16px;
    font-weight: bold;
  }

  .upload-button-text {
    color: #808080;
    margin-left: 2px;
    font-size: 16px;
    font-weight: bold;
  }

  /* The Modal (background) */
  .modal-image {
    display: block;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.9);
  }

  /* Modal Content (image) */
  .modal-image-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
  }

  /* Add Animation */
  .modal-image-content {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
  }

  @-webkit-keyframes zoom {
    from {-webkit-transform:scale(0)}
    to {-webkit-transform:scale(1)}
  }

  @keyframes zoom {
    from {transform:scale(0)}
    to {transform:scale(1)}
  }

  /* The Close Button */
  .modal-image-close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
    cursor: pointer;
  }

  /* 100% Image Width on Smaller Screens */
  @media only screen and (max-width: 700px){
    .modal-image-content {
      width: 100%;
    }
  }
</style>
